var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("v-icon", { staticClass: "white-text close-icon" }, [
        _vm._v("mdi-close"),
      ]),
      _c("v-row", { staticClass: "primary ", staticStyle: { height: "30%" } }, [
        _c(
          "div",
          {
            staticClass:
              "w-100 d-flex flex-column align-center justify-center pa-4",
          },
          [
            _c("h1", { staticClass: "font-weight-bold white--text" }, [
              _vm._v("Plan Listing"),
            ]),
            _c(
              "div",
              { staticClass: "font-weight-regular white--text text-center" },
              [_vm._v(" Select plan to proceed further")]
            ),
          ]
        ),
      ]),
      _c(
        "v-row",
        { staticClass: "justify-center plan-box " },
        [
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "rounded-lg pa-8 h-100 d-flex flex-column align-center justify-center",
                  attrs: { elevation: "3" },
                },
                [
                  _c("h2", { staticClass: "font-weight-regular" }, [
                    _vm._v("GENERAL"),
                  ]),
                  _c("div", { staticClass: " d-flex align-center mt-6" }, [
                    _c("h1", { staticClass: "font-weight-bold " }, [
                      _vm._v("$500"),
                    ]),
                    _c("span", { staticClass: "mt-2" }, [_vm._v("/ month ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular primary-text mt-3" },
                    [_vm._v("Try it as long as you like")]
                  ),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("Create Campaign"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("Keyword Analysis"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("Automation Rules"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("Keyword Optimization"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "250",
                        color: "primary",
                        depressed: "",
                        large: "",
                      },
                    },
                    [_vm._v(" Buy Now ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass:
                    "pa-8 rounded-lg  h-100 d-flex flex-column align-center justify-center",
                  attrs: { elevation: "3" },
                },
                [
                  _c("div", { staticClass: "ribbon" }, [
                    _c("span", { staticClass: "ribbon__content" }, [
                      _vm._v("Premium"),
                    ]),
                  ]),
                  _c("h2", { staticClass: "font-weight-regular " }, [
                    _vm._v("ENTERPRISE"),
                  ]),
                  _c("p", { staticClass: " secondary-dark mb-0" }, [
                    _vm._v("STARTS AT"),
                  ]),
                  _c("div", { staticClass: " d-flex align-center" }, [
                    _c("h1", { staticClass: "font-weight-bold " }, [
                      _vm._v("$500"),
                    ]),
                    _c("span", { staticClass: "mt-2" }, [_vm._v("/ month ")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "font-weight-regular primary-text mt-3" },
                    [_vm._v("Limtless possibilites")]
                  ),
                  _c(
                    "v-list",
                    [
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("$500 with 5% of ASA monthly"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("$2,000 with 4% of ASA monthly"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("$5,000 with 2.5% of ASA monthly"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-list-item",
                        [
                          _c(
                            "v-list-item-content",
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _c(
                                    "v-icon",
                                    { staticClass: "mr-3 secondary-dark" },
                                    [
                                      _vm._v(
                                        "mdi-checkbox-marked-circle-outline"
                                      ),
                                    ]
                                  ),
                                  _vm._v("$10,000 with 2.5% of ASA monthly"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        width: "250",
                        color: "primary",
                        depressed: "",
                        large: "",
                      },
                    },
                    [_vm._v(" Buy Now ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }